import { useMemo, useState } from 'react';
import styled from 'styled-components';

type CAMT54Transaction = {
	customerId: string;
	amount: number;
	customerName: string;
};

type Pain008Transaction = {
	customerId: string;
	amount: number;
	customerName: string;
};

export const Rejets = () => {
	const [rejetsString, setRejetsString] = useState('');
	const [baseFile, setBaseFile] = useState('');

	//Handler for file load
	const handleFileLoad = (
		event: React.ChangeEvent<HTMLInputElement>,
		mode: 'base' | 'rejets',
	) => {
		const file = event.target.files?.[0];
		if (!file) {
			return;
		}
		const reader = new FileReader();
		reader.onload = event => {
			const content = event.target?.result;
			if (typeof content !== 'string') {
				return;
			}
			if (mode === 'base') {
				setBaseFile(content);
			} else {
				setRejetsString(content);
			}
		};
		reader.readAsText(file);
	};

	//Parsing the string once it is loaded
	const camt54List: CAMT54Transaction[] = useMemo(() => {
		if (!rejetsString) return [];
		//Creates a new DOMParser
		const parser = new DOMParser();
		const xml = parser.parseFromString(rejetsString, 'text/xml');
		//Selects all the transactions details in the XML
		const transactions = xml.querySelectorAll('TxDtls');
		const camt54Transactions: CAMT54Transaction[] = [];
		//Loop over the transactions and get the relevant data
		transactions.forEach(transaction => {
			const customerId = transaction.querySelector('InstrId')?.textContent;
			const amount = parseFloat(
				transaction.querySelector('Amt')?.textContent || '0',
			);
			const customerName = transaction.querySelector('Nm')?.textContent;
			camt54Transactions.push({
				customerId: customerId || '',
				amount,
				customerName: customerName || '',
			});
		});
		return camt54Transactions;
	}, [rejetsString]);

	const pain008List: Pain008Transaction[] = useMemo(() => {
		if (!baseFile) return [];
		const parser = new DOMParser();
		const xml = parser.parseFromString(baseFile, 'text/xml');
		const transactions = xml.querySelectorAll('DrctDbtTxInf');
		const pain008Transactions: Pain008Transaction[] = [];
		transactions.forEach(transaction => {
			const customerId = transaction.querySelector('InstrId')?.textContent;
			const amount = parseFloat(
				transaction.querySelector('InstdAmt')?.textContent || '0',
			);
			const customerName = transaction.querySelector('Nm')?.textContent;
			pain008Transactions.push({
				customerId: customerId || '',
				amount,
				customerName: customerName || '',
			});
		});
		return pain008Transactions;
	}, [baseFile]);

	const acceptedTransactions = useMemo(() => {
		if (camt54List.length === 0 || pain008List.length === 0) return [];
		const acceptedTransactions: Pain008Transaction[] = [];
		//If a transaction is IN the camt54 list, it is accepted
		pain008List.forEach(pain008Tx => {
			const isAccepted = camt54List.some(camt54Tx => {
				return camt54Tx.customerId === pain008Tx.customerId;
			});
			if (isAccepted) {
				acceptedTransactions.push({
					...pain008Tx,
					customerId: pain008Tx.customerId.split('-')[1],
				});
			}
		});
		return acceptedTransactions;
	}, [camt54List, pain008List]);

	const rejectedTransactions = useMemo(() => {
		if (camt54List.length === 0 || pain008List.length === 0) return [];
		const rejectedTransactions: Pain008Transaction[] = [];
		//If a transaction is NOT in the camt54 list, it is rejected
		pain008List.forEach(pain008Tx => {
			const isRejected = !camt54List.some(camt54Tx => {
				return camt54Tx.customerId === pain008Tx.customerId;
			});
			if (isRejected) {
				rejectedTransactions.push({
					...pain008Tx,
					customerId: pain008Tx.customerId.split('-')[1],
				});
			}
		});
		return rejectedTransactions;
	}, [camt54List, pain008List]);

	return (
		<div>
			<h1>Rejets</h1>
			<InputSection>
				<FileInputContainer>
					<label>Veuillez insérer un fichier de de prélèvement (.xml)</label>
					<input type="file" onChange={e => handleFileLoad(e, 'base')} />
				</FileInputContainer>
				<FileInputContainer>
					<label>Merci d'insérer un fichier de rejet (.xml)</label>
					<input type="file" onChange={e => handleFileLoad(e, 'rejets')} />
				</FileInputContainer>
			</InputSection>
			<ResultSection>
				<ResultContainer>
					<h2>Transactions acceptées</h2>
					<ListContent>
						<ListContainer>
							{acceptedTransactions.map(tx => (
								<li key={tx.customerId}>
									{tx.customerId}. {tx.customerName}
								</li>
							))}
						</ListContainer>
					</ListContent>
				</ResultContainer>
				<ResultContainer>
					<h2>Transactions rejetées</h2>
					<ListContent>
						<ListContainer>
							{rejectedTransactions.map(tx => (
								<li key={tx.customerId}>
									{tx.customerId}. {tx.customerName}
								</li>
							))}
						</ListContainer>
					</ListContent>
				</ResultContainer>
			</ResultSection>
		</div>
	);
};

const FileInputContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const InputSection = styled.div`
	display: flex;
	flex-direction: row;
	gap: 2rem;
	margin-bottom: 3rem;
`;

const ResultSection = styled.div`
	display: flex;
	flex-direction: row;
	gap: 2rem;
`;

const ResultContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const ListContent = styled.div`
	flex: 1;
	padding: 1rem;
	border: 1px solid #ccc;
	border-radius: 5px;
	overflow-y: auto;
	background-color: white;
`;

const ListContainer = styled.ul`
	list-style: none;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;
