import { getEnv } from 'utils/common/env';

const isDevelopment = getEnv('ENV') === 'development';

export const FeatureFlip = {
	objectives: { isAvailable: isDevelopment },
	orders: { isAvailable: true },
	institute: {
		fiscalYearPeriod: { isAvailable: isDevelopment },
	},
	stats: { isAvailable: true },
};
