import { useCallback, useEffect, useMemo } from 'react';
import { useForm as useHookForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { includes } from 'lodash';

import { Customer } from 'types/API/customers';
import { Optional } from 'types/misc';
import { useMutationOptions } from 'app/hooks/modal/useMutationsOptions';
import { RequestError } from 'utils/request/types';

import { FormValues } from './types';
import { useFormValidation } from '../useFormValidation';
import { useMutations } from '../useMutations';
import { APIValueErrorDescription } from 'types/API/error';
import { getViolations } from 'utils/error';
import { useFormData } from '../useFormData';

interface Options {
  customer: Optional<Customer.Normal>;
  instituteId: number;
  isDeptingSubscription: boolean;
  subscriptionId?: number;
  onSuccess: () => void;
}

export const useForm = (options: Options) => {
  const {
    customer,
    instituteId,
    isDeptingSubscription,
    subscriptionId,
    onSuccess,
  } = options;

  const { schema } = useFormValidation();

  const { isUpdating, updateSubscriptionMutation } = useMutations(instituteId);

  const { errorMapping, getFormValues, getPayloads } = useFormData();

  const { values, subscriptionPaymentId } = useMemo(
    () => getFormValues(customer),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customer],
  );

  const methods = useHookForm<FormValues>({
    defaultValues: values,
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const {
    formState: { isSubmitting: isFormSubmitting, isValid },
    handleSubmit,
    reset,
    setError,
  } = methods;

  useEffect(() => {
    reset(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const isSubmitting = isFormSubmitting || isUpdating;

  const mutationOptions = useMutationOptions({
    successCallback: (data, variables, context) => {
      onSuccess();
    },
    errorCallback: (rawError: Error) => {
      const error = rawError as RequestError<APIValueErrorDescription>;
      const violations = getViolations(error);
      violations
        .filter(violation =>
          includes(Object.keys(errorMapping), violation.propertyPath),
        )
        .forEach(violation => {
          const mapping = errorMapping[violation.propertyPath];
          setError(mapping.filedName, {
            message: mapping.message,
          });
        });
    },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      if (!customer) {
        return;
      }

      let notANumber = isNaN(+formValues.customer.zipcode)

      if (formValues.customer.zipcode.length < 4 || formValues.customer.zipcode.length > 5) {
        alert("Le code postal n'est pas au bon format")
        return;
      }
      if (notANumber) {
        alert("Le code postal n'est pas au bon format")
        return
      }

      const {
        customerPayload,
        subscriptionPayload,
        subscriptionPaymentPayload,
      } = getPayloads({
        subscriptionId: customer.subscriptionsInstitute ? customer.subscriptionsInstitute[0].id :  customer.subscriptions[0].id, 
        formValues,
      });

      updateSubscriptionMutation(
        {
          customerId: customer.id,
          customerPayload,
          isDeptingSubscription,
          subscriptionId,
          subscriptionPayload,
          subscriptionPaymentId,
          subscriptionPaymentPayload,
        },
        mutationOptions,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customer],
  );

  return {
    canSubmit: isValid && !isSubmitting,
    isSubmitting,
    methods,
    onSubmit: handleSubmit(onSubmit),
  };
};
