import { Subscription } from 'types/API/subscriptions';
import { request } from 'utils/request';

export const createSubscriptionPaymentRequest = ({
  data,
}: {
  data: Subscription.Payment.Payload;
}) =>
  request<Subscription.Payment.Normal>('api/subscription_payments', {
    method: 'POST',
    data,
  });

export const updateSubscriptionPaymentRequest = ({
  subscriptionPaymentId,
  data,
}: {
  subscriptionPaymentId: number;
  data: Subscription.Payment.Payload;
}) =>
  request<Subscription.Payment.Normal>(
    `api/subscription_payments/validate/${subscriptionPaymentId}`,
    {
      method: 'PUT',
      data,
    },
  );
