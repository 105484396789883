import { objectToXml } from './xml';
import {
	extractClearingKeyFromIBAN,
	generateBVRNumber,
	generateCollectionDate,
	generateControlSum,
	generateCreationDateTime,
	generateMessageId,
	generatePaymentMessageId,
	normalizeDiacritics,
	PrelevementInput,
} from './common';

type DirectDebitTransactionInfo = {
	PmtId: {
		InstrId: string;
		EndToEndId: 'NOTPROVIDED';
	};
	InstdAmt: string;
	DbtrAgt: {
		FinInstnId: {
			ClrSysMmbId: {
				MmbId: string;
			};
		};
	};
	Dbtr: {
		Nm: string;
		PstlAdr: {
			Ctry: 'CH';
			AdrLine: string;
		};
	};
	DbtrAcct: {
		Id: {
			IBAN: string;
		};
	};
	RmtInf: {
		Strd: {
			CdtrRefInf: {
				Tp: {
					CdOrPrtry: {
						Prtry: 'ESR';
					};
				};
				Ref: string;
			};
		};
	};
};

type GroupHeader = {
	MsgId: string;
	CreDtTm: string;
	NbOfTxs: string;
	CtrlSum: string;
	InitgPty: {
		Nm: string;
		Id: {
			OrgId: {
				Othr: {
					Id: string; //Identifiant LSV+
				};
			};
		};
	};
};

type PaymentInformation = {
	PmtInfId: string;
	PmtMtd: 'DD';
	PmtTpInf: {
		SvcLvl: {
			Prtry: 'CHTA';
		};
		LclInstrm: {
			Prtry: 'LSV+';
		};
	};
	ReqdColltnDt: string;
	Cdtr: {
		Nm: string;
	};
	CdtrAcct: {
		Id: {
			IBAN: string;
		};
	};
	CdtrAgt: {
		FinInstnId: {
			ClrSysMmbId: {
				MmbId: string;
			};
			Othr: {
				Id: string;
			};
		};
	};
	CdtrSchmeId: {
		Id: {
			PrvtId: {
				Othr: {
					Id: string;
					SchmeNm: {
						Prtry: 'CHLS';
					};
				};
			};
		};
	};
	DrctDbtTxInf: DirectDebitTransactionInfo[];
};

export type CHTADocument = {
	CstmrDrctDbtInitn: {
		GrpHdr: GroupHeader;
		PmtInf: PaymentInformation;
	};
};

const generateCHTA = (prelevement: PrelevementInput): CHTADocument => {
	const groupHeader: GroupHeader = {
		MsgId: generateMessageId(),
		CreDtTm: generateCreationDateTime(),
		NbOfTxs: prelevement.transactions.length.toString(),
		CtrlSum: generateControlSum(prelevement.transactions),
		InitgPty: {
			Nm: normalizeDiacritics(prelevement.institut.nom),
			Id: {
				OrgId: {
					Othr: {
						Id: prelevement.institut.bank_id,
					},
				},
			},
		},
	};

	const paymentInformation: PaymentInformation = {
		PmtInfId: generatePaymentMessageId(prelevement),
		PmtMtd: 'DD',
		PmtTpInf: {
			SvcLvl: {
				Prtry: 'CHTA',
			},
			LclInstrm: {
				Prtry: 'LSV+',
			},
		},
		ReqdColltnDt: generateCollectionDate(prelevement.date_payment),
		Cdtr: {
			Nm: normalizeDiacritics(prelevement.institut.nom),
		},
		CdtrAcct: {
			Id: {
				IBAN: prelevement.institut.iban,
			},
		},
		CdtrAgt: {
			FinInstnId: {
				ClrSysMmbId: {
					MmbId: extractClearingKeyFromIBAN(prelevement.institut.iban),
				},
				Othr: {
					Id: prelevement.institut.num_adherant_bvr,
				},
			},
		},
		CdtrSchmeId: {
			Id: {
				PrvtId: {
					Othr: {
						Id: prelevement.institut.bank_id,
						SchmeNm: {
							Prtry: 'CHLS',
						},
					},
				},
			},
		},
		DrctDbtTxInf: prelevement.transactions.map(t => ({
			PmtId: {
				InstrId: `InstrId-${t.id}`,
				EndToEndId: 'NOTPROVIDED',
			},
			InstdAmt: t.amount.toFixed(2),
			DbtrAgt: {
				FinInstnId: {
					ClrSysMmbId: {
						MmbId: extractClearingKeyFromIBAN(t.debtor.iban),
					},
				},
			},
			Dbtr: {
				Nm: normalizeDiacritics(t.debtor.name),
				PstlAdr: {
					Ctry: 'CH',
					AdrLine: normalizeDiacritics(t.debtor.address),
				},
			},
			DbtrAcct: {
				Id: {
					IBAN: t.debtor.iban,
				},
			},
			RmtInf: {
				Strd: {
					CdtrRefInf: {
						Tp: {
							CdOrPrtry: {
								Prtry: 'ESR',
							},
						},
						Ref: generateBVRNumber(
							prelevement.institut.id,
							t,
							prelevement.institut.num_participant_lsv,
						),
					},
				},
			},
		})),
	};

	return {
		CstmrDrctDbtInitn: {
			GrpHdr: groupHeader,
			PmtInf: paymentInformation,
		},
	};
};

const ChTAAttributes = {
	InstdAmt: [{ Ccy: 'CHF' }],
};

//Generate an XML string from a PrelevementInput object
export const generateCHTA_XML = (prelevement: PrelevementInput) => {
	const output = [
		'<?xml version="1.0" encoding="UTF-8"?>',
		'<Document xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.six-interbank-clearing.com/de/pain.008.001.02.ch.03.xsd" xsi:schemaLocation="http://www.six-interbank-clearing.com/de/pain.008.001.02.ch.03.xsd pain.008.001.02.ch.03.xsd">',
		objectToXml(generateCHTA(prelevement), ChTAAttributes, 1),
		'</Document>',
	].join('\n');

	return output;
};
