type XMLValue = string | number | boolean | null | XMLObject | XMLValue[];
type XMLObject = { [key: string]: XMLValue };
type XMLAttribute = { [key: string]: string };
type XMLKeyAttributes = { [key: string]: XMLAttribute[] };

//Convert a valid JS object into a formatted xml string
export const objectToXml = (
	obj: XMLObject,
	attributeMap?: XMLKeyAttributes,
	depth = 0,
): string => {
	// Indentation string based on the current depth of the document
	const indent = '  '.repeat(depth);

	//We map over every key-value pair in the object
	const output = Object.entries(obj).map(([key, value]) => {
		//If the key is present in the attrib object, we map over the attributes
		const attributes = attributeMap && attributeMap[key] ? attributeMap[key] : [];
		let attributesString = attributes
			.map(attrib => {
				return Object.entries(attrib)
					.map(([key, value]) => `${key}="${value}"`)
					.join(' ');
			})
			.join(' ');

		//If we have attributes, we add a space before them
		if (attributesString) attributesString = ' ' + attributesString;

		//If the value is an array, we map over it to generate repeated xml tags
		if (Array.isArray(value)) {
			return value
				.map(item => {
					//If the item is an object, we recursively call objectToXml with an increased depth
					if (item && typeof item === 'object') {
						return `${indent}<${key}${attributesString}>\n${objectToXml(
							item as XMLObject,
							attributeMap,
							depth + 1,
						)}\n${indent}</${key}>`;
					}
					//Otherwise we just generate the xml tag
					return `${indent}<${key}${attributesString}>${item}</${key}>`;
				})
				.join('\n');
		}
		//If the value is an object, we recursively call objectToXml with an increased depth
		if (typeof value === 'object' && value !== null) {
			return `${indent}<${key}${attributesString}>\n${objectToXml(
				value as XMLObject,
				attributeMap,
				depth + 1,
			)}\n${indent}</${key}>`;
		}
		//Otherwise we just generate the xml tag
		return `${indent}<${key}${attributesString}>${value}</${key}>`;
	});
	return output.join('\n');
};
