import React, { createContext, useContext, useState, ReactNode } from 'react';

interface TotalContextType {
  total: number;
  Totalupdate: (newTotal: number) => void;
  Quantityupdate:(newQantity: number) => void;
  quantity: number
  currentAmountOfFreeProducts: number;  
  currentAmountOfFreeProductsUpdate:(newQantity: number) => void;
  availableAmountOfFreeProducts: number;  
  availableAmountOfFreeProductsUpdate:(newQantity: number) => void;
}

const TotalContext = createContext<TotalContextType | undefined>(undefined);

export const TotalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [total, setTotal] = useState(0);
  const [quantity , setQuantity] = useState(0);
  const [currentAmountOfFreeProducts , setCurrentAmountOfFreeProducts] = useState(0);
  const [availableAmountOfFreeProducts , setAvailableAmountOfFreeProducts] = useState(0);
  
  const Totalupdate = (newTotal: number) => {
    setTotal(newTotal);
  };

  const Quantityupdate = (newQantity: number) => {
    setQuantity(newQantity);
  };

  const currentAmountOfFreeProductsUpdate = (newQantity: number) => {
    setCurrentAmountOfFreeProducts(newQantity);
  };

  const availableAmountOfFreeProductsUpdate = (newQantity: number) => {
    setAvailableAmountOfFreeProducts(newQantity);
  };

  return (
    <TotalContext.Provider value={{ total, Totalupdate, Quantityupdate, quantity, currentAmountOfFreeProducts, currentAmountOfFreeProductsUpdate, availableAmountOfFreeProducts, availableAmountOfFreeProductsUpdate }}>
      {children}
    </TotalContext.Provider>
  );
};

export const useTotal = (): TotalContextType => {
  const context = useContext(TotalContext);
  if (context === undefined) {
    throw new Error('useTotal must be used within a TotalProvider');
  }
  return context;
};