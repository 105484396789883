import { objectToXml } from './xml';
import {
	generateCollectionDate,
	generateControlSum,
	generateCreationDateTime,
	generateMessageId,
	generatePaymentMessageId,
	PrelevementInput,
} from './common';

type DirectDebitTransactionInfo = {
	PmtId: {
		InstrId: string;
		EndToEndId: string;
	};
	InstdAmt: string;
	DbtrAgt: {
		FinInstnId: {
			ClrSysMmbId: {
				MmbId: '09000';
			};
		};
	};
	Dbtr: {
		Nm: string;
		PstlAdr: {
			Ctry: 'CH';
			AdrLine: string;
		};
	};
	DbtrAcct: {
		Id: {
			IBAN: string;
		};
	};
};

type GroupHeader = {
	MsgId: string;
	CreDtTm: string;
	NbOfTxs: number;
	CtrlSum: string;
	InitgPty: {
		Nm: string;
		Id: {
			OrgId: {
				Othr: {
					Id: string;
				};
			};
		};
	};
};

type PaymentInformation = {
	PmtInfId: string;
	PmtMtd: 'DD';
	PmtTpInf: {
		SvcLvl: {
			Prtry: 'CHDD';
		};
		LclInstrm: {
			Prtry: 'DDCOR1';
		};
	};
	ReqdColltnDt: string;
	Cdtr: {
		Nm: string;
	};
	CdtrAcct: {
		Id: {
			IBAN: string;
		};
	};
	CdtrAgt: {
		FinInstnId: {
			ClrSysMmbId: {
				MmbId: '09000';
			};
		};
	};
	CdtrSchmeId: {
		Id: {
			PrvtId: {
				Othr: {
					Id: string;
					SchmeNm: {
						Prtry: 'CHDD';
					};
				};
			};
		};
	};
	DrctDbtTxInf: DirectDebitTransactionInfo[];
};

export type ChDDDocument = {
	CstmrDrctDbtInitn: {
		GrpHdr: GroupHeader;
		PmtInf: PaymentInformation;
	};
};

const generateTransactionInfo = (
	transaction: PrelevementInput['transactions'][number],
	idx: number,
): DirectDebitTransactionInfo => {
	return {
		PmtId: {
			InstrId: `InstrId-${transaction.id}`,
			EndToEndId: `EndToEndId-${transaction.id}`,
		},
		InstdAmt: transaction.amount.toFixed(2),
		DbtrAgt: {
			FinInstnId: {
				ClrSysMmbId: {
					MmbId: '09000',
				},
			},
		},
		Dbtr: {
			Nm: transaction.debtor.name.toUpperCase(),
			PstlAdr: {
				Ctry: 'CH',
				AdrLine: transaction.debtor.address,
			},
		},
		DbtrAcct: {
			Id: {
				IBAN: transaction.debtor.iban,
			},
		},
	};
};

const generateChDD = (prelevement: PrelevementInput): ChDDDocument => {
	const groupHeader: GroupHeader = {
		MsgId: generateMessageId(),
		CreDtTm: generateCreationDateTime(),
		NbOfTxs: prelevement.transactions.length,
		CtrlSum: generateControlSum(prelevement.transactions),
		InitgPty: {
			Nm: prelevement.institut.nom,
			Id: {
				OrgId: {
					Othr: {
						Id: prelevement.institut.bank_id,
					},
				},
			},
		},
	};

	const paymentInformation: PaymentInformation = {
		PmtInfId: generatePaymentMessageId(prelevement),
		PmtMtd: 'DD',
		PmtTpInf: {
			SvcLvl: {
				Prtry: 'CHDD',
			},
			LclInstrm: {
				Prtry: 'DDCOR1',
			},
		},
		ReqdColltnDt: generateCollectionDate(prelevement.date_payment),
		Cdtr: {
			Nm: prelevement.institut.nom,
		},
		CdtrAcct: {
			Id: {
				IBAN: prelevement.institut.iban,
			},
		},
		CdtrAgt: {
			FinInstnId: {
				ClrSysMmbId: {
					MmbId: '09000',
				},
			},
		},
		CdtrSchmeId: {
			Id: {
				PrvtId: {
					Othr: {
						Id: prelevement.institut.bank_id,
						SchmeNm: {
							Prtry: 'CHDD',
						},
					},
				},
			},
		},
		DrctDbtTxInf: prelevement.transactions.map(generateTransactionInfo),
	};

	return {
		CstmrDrctDbtInitn: {
			GrpHdr: groupHeader,
			PmtInf: paymentInformation,
		},
	};
};

const ChDDAttributes = {
	InstdAmt: [{ Ccy: 'CHF' }],
};

export const generateChDD_XML = (prelevement: PrelevementInput) => {
	const output = [
		'<?xml version="1.0" encoding="UTF-8"?>',
		'<Document xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.six-interbank-clearing.com/de/pain.008.001.02.ch.03.xsd" xsi:schemaLocation="http://www.six-interbank-clearing.com/de/pain.008.001.02.ch.03.xsd pain.008.001.02.ch.03.xsd">',
		objectToXml(generateChDD(prelevement), ChDDAttributes, 1),
		'</Document>',
	].join('\n');

	return output;
};
