import { UseQueryResult } from 'react-query';

import { useQuery } from 'app/hooks';
import { genericQueryKey } from 'utils/cache/keys';
import { fetchProductsRequest } from 'app/services/products';
import { Product } from 'types/API/products';
import { fetchAllBtoBProductsRequest } from 'app/services/btobproducts';

export const useProductsQuery = (options: {
  filter?: Product.Type;
  instituteId: number;
}) => {
  const { filter, instituteId } = options;
  const parameters = { instituteId };

  let queryState = useQuery(
    genericQueryKey({
      identifier: 'products',
      parameters,
    }),
    () => fetchProductsRequest(parameters),
  );

  if (filter) {
    const { data, ...rest } = queryState;
    queryState = {
      data: data?.filter(product => product.productType === filter),
      ...rest,
    } as UseQueryResult<Product.Normal[], Error>;
  }
  return queryState;
};


export const useBtoBProductsQuery = (options: {
  instituteId: number;
}) => {
  const { instituteId } = options;
  const parameters = { instituteId: instituteId, gammeId: 0, categorieId: 0 };

  let queryState = useQuery(
    genericQueryKey({
      identifier: 'products',
      parameters,
    }),
    () => fetchAllBtoBProductsRequest(parameters),
  );

  return queryState;
};
