import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { FormWrapper } from 'app/components/Forms/FormWrapper';
import { PageHeader } from 'app/components/PageHeaders/PageHeader';
import { PageWrapper } from 'app/components/PageWrappers/PageWrapper';
import { LoadableContent } from 'app/components/LoadableContent';
import { useInstituteSelector } from 'app/atoms/selectors';

import { messages } from './messages';
import { useForm, useData } from './hooks';
import { Form } from './Form';
import { Institute } from 'types/API/institutes';
import { request } from 'utils/request';
import { hasEntitlement } from 'utils/roles/hasEntitlement';
import { useRecoilValue } from 'recoil';
import { meState } from 'app/atoms';
import styled from 'styled-components';

export const InstitutePage = () => {
	const { t } = useTranslation();

	const { institute: cachedInstitute, instituteId } = useInstituteSelector();

	const pageTitle = t(messages.pageTitle(), {
		institute_name: cachedInstitute.name,
	});

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const [institute, setInstitute] = useState<Institute.Full>();

	const { canSubmit, isSubmitting, methods, onSubmit } = useForm({
		instituteId,
		institute: institute,
	});

	const me = useRecoilValue(meState);
	const isSuperAdmin = hasEntitlement('super_admin', me);

	const loadData = async () => {
		if (!loading) {
			setLoading(true);
			fetchInstituteRequest({ instituteId: instituteId })
				.then(data => {
					setLoading(false);
					if (data) {
						setInstitute(data);
					}
				})
				.catch(error => {
					setLoading(false);
					setError(error);
					console.log(error);
				});
		}
	};

	useEffect(() => {
		loadData();
		return () => {};
	}, [instituteId]);

	return (
		<>
			<Helmet>
				<title>{pageTitle}</title>
			</Helmet>
			<PageWrapper header={<PageHeader label={pageTitle} />}>
				<LoadableContent error={error} isLoading={loading}>
					<WarningBlock>
						<p>
							[NF525] : Afin de se conformer à la NF525, il n'est plus possible pour
							les franchisés d'éditer les données institut. En cas de besoin de
							modification des données ci-après, merci de contacter le support
							informatique.
						</p>
					</WarningBlock>
					<FormWrapper
						id="institute-edit-form"
						methods={methods}
						onSubmit={onSubmit}
					>
						<Form
							canSubmit={canSubmit}
							isEditable={isSuperAdmin}
							isSubmitting={isSubmitting}
							institute={institute}
						/>
					</FormWrapper>
				</LoadableContent>
			</PageWrapper>
		</>
	);
};

const WarningBlock = styled.div`
	color: #f00;
	border: 1px solid red;
	text-align: center;
	padding: 10px;
	margin: 20px;
	border-radius: 20px;
`;

export const fetchInstituteRequest = ({
	instituteId,
}: {
	instituteId: number;
}) =>
	request<Institute.Full>(`api/institutes/${instituteId}`, {
		method: 'GET',
	});
