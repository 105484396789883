import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { messages } from 'app/i18n/messages';

export const useFormValidation = () => {
  const { t } = useTranslation();

  const schema = useMemo(
    () =>
      yup.object({
        customer: yup.object().shape({
          firstname: yup
            .string()
            .required(t(messages.forms.requiredFieldError())),
          lastname: yup
            .string()
            .required(t(messages.forms.requiredFieldError())),
          phone: yup.string(),
          email: yup
            .string()
            .email(t(messages.forms.invalidEmailError()))
            .required(t(messages.forms.requiredFieldError())),
          address: yup
            .string()
            .required(t(messages.forms.requiredFieldError())),
          complement: yup.string(),
          zipcode: yup
            .string()
            .required(t(messages.forms.requiredFieldError())),
          city: yup.string().required(t(messages.forms.requiredFieldError())),
        }),
        subscriptionPayment: yup.object().shape({
          iban: yup
          .string()
          .test('Espaces dans les Iban', "L'Iban ne doit pas contenir d'espaces.", (value) => !(/\s/.test(value ? value! : "")))

          //.matches(/\s/, {message : "L'Iban ne doit pas contenir d'espaces."})
        }),
      }),
    [t],
  );

  return { schema };
};
